<template>
    <div class="jointly-info-table-page">
        <!--工具栏-->
        <div class="toolbar" id="toolbar" ref="toolbar">
            <!--标题-->
            <div class="title-box">
                <div @click="Route.back()" style="font-weight: bold; font-size: 23px; cursor: pointer; color: #ed4014" class="liefeng-icon liefeng-icon-direction-left" v-if="Route.isBack()"></div>
                <div style="font-size: 23px" class="liefeng-icon liefeng-icon-dynamic-filling" v-else></div>
                <div style="padding: 0 6px; font-size: 16px; white-space: nowrap; cursor: pointer" ref="title" @click="Route.back()">辖区共建信息</div>
            </div>

            <div class="tool-box">
                <div class="button-box">
                    <Button icon="md-sync" v-if="tabMenuActivity === 'all'" type="info" @click="onSyncPlaceName">同步到地名+</Button>
                    <Button @click="$refs.merchant_opening.display()" style="margin-left: 10px">商户号开通情况</Button>
                    <Button icon="md-add" type="primary" @click="onDisplayForm()" style="margin-left: 10px">新增</Button>
                </div>
            </div>
        </div>
        <div class="content-box">
            <div class="search-box">
                <!-- 搜索组件表单 -->
                <Form ref="search_form" :model="searchData" :label-colon="true" :inline="true" class="search">
                    <Input v-model.trim="searchData.name" placeholder="请输入名称" style="width: 160px"></Input>
                    <Input v-model.trim="searchData.applyAccount" placeholder="申请账号" style="width: 160px"></Input>
                    <Select v-model="searchData.lifeDisplay" placeholder="显示状态" style="width: 100px">
                        <Option value="1">显示</Option>
                        <Option value="2">隐藏</Option>
                    </Select>
                    <Select v-model="searchData.businessStatus" placeholder="营业状态" style="width: 100px">
                        <Option value="1">营业中</Option>
                        <Option value="2">停业</Option>
                    </Select>
                </Form>
                <!-- 搜索操作按钮：存在搜索条件时 -->
                <Button class="btn" type="primary" icon="ios-search" @click="onSearch">查询</Button>
                <Button class="btn" type="success" icon="ios-refresh" @click="onReset">重置</Button>
            </div>
            <div class="table-box">
                <div class="table-left">
                    <Menu theme="light" :active-name="tabMenuActivity" @on-select="onSelectMenu">
                        <MenuGroup title="信息状态">
                            <MenuItem name="review">待审核</MenuItem>
                            <MenuItem name="merchant">
                                商家
                                <Tooltip max-width="500px" placement="top" content="已审核信息中通过商家申请的信息" transfer><Icon type="ios-help-circle-outline" size="20" /></Tooltip>
                            </MenuItem>
                            <!-- <MenuItem name="on_line">
                                线上服务
                                <Tooltip max-width="500px" placement="top" content="已审核信息中提供线上服务的信息"><Icon type="ios-help-circle-outline" size="20" /></Tooltip>
                            </MenuItem> -->
                            <MenuItem name="stop_business">
                                已停业
                                <Tooltip max-width="500px" placement="top" content="已审核中已停业的信息" transfer><Icon type="ios-help-circle-outline" size="20" /></Tooltip>
                            </MenuItem>
                            <MenuItem name="all">
                                全部已审核信息
                                <Tooltip max-width="500px" placement="top" content="关联了机构的所有信息" transfer><Icon type="ios-help-circle-outline" size="20" /></Tooltip>
                            </MenuItem>
                        </MenuGroup>
                    </Menu>
                </div>
                <!-- 表格组件 -->
                <elem-table
                    ref="elem_table"
                    :isSelector="tabMenuActivity === 'all'"
                    :columns="columns"
                    :table-api="table_url"
                    :selected="processSyncPlaceNameSelected"
                    @on-change-selection="onChangeSelection"
                ></elem-table>
                <!-- 表单组件 -->
                <data-form ref="data_form" @on-submit="onReset"></data-form>
                <!-- 表单步骤组件 -->
                <data-step-form ref="data_step_form" @on-submit="onReset"></data-step-form>
                <!-- 详情配置组件 -->
                <ElemDetail ref="elem_datail"></ElemDetail>
            </div>
        </div>

        <Mechanism ref="mechanism" @on-change="onChangeMechanism()"></Mechanism>
        <!-- 加入黑名单 -->
        <AddConceal ref="add_conceal"></AddConceal>
        <!-- 店铺管理员 -->
        <Staff ref="staff"></Staff>
        <!-- 商户申请资料 -->
        <ApplyData ref="applyData"></ApplyData>
        <!-- 商户开通情况 -->
        <MerchantOpening ref="merchant_opening"></MerchantOpening>

        <CompModal title="畅联通" ref="modal" width="400px" @on-close="iframeUrl = null">
            <iframe v-if="iframeUrl" :src="iframeUrl" width="100%" height="100%" frameborder="0" style="display: block"></iframe>
        </CompModal>
    </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3.vue"
import LiefengModal from "@/components/LiefengModal.vue"
import DataForm from "./form"
import DataStepForm from "./form_step"
import ElemTable from "../components/elem-table.vue"
import ElemDetail from "./elem-detail.vue"
import Route from "../../communitymobilization/entity/Route"

import MerchantOpening from "./childrens/merchant_opening.vue"
import Service from "./childrens/service.vue"
import Mechanism from "./childrens/mechanism.vue"
import AddConceal from "./childrens/add_conceal.vue"
import Staff from "./childrens/staff.vue"
import ApplyData from "./childrens/applyData.vue"
import Request from "../utils/request"
import CompModal from "../../residentdatabase/components/CompModal.vue"
import proxy from "@/api/proxy"

export default {
    components: { LiefengContent, LiefengModal, DataForm, DataStepForm, ElemTable, ElemDetail, Service, Mechanism, AddConceal, Staff, ApplyData, MerchantOpening, CompModal },

    data() {
        return {
            Route: Route,
            DataForm,
            DataStepForm,
            table_url: null,
            columns: null,

            tabMenuActivity: "review",

            openServiceStatus: false,
            serviceCode: "",

            // 设置下级机构
            subordinateStatus: false,
            showNumberSub: 0,
            subOrgCode: "",

            // 搜索参数
            searchData: {},

            iframeUrl: null,
        }
    },

    watch: {
        tabMenuActivity: {
            handler(v, o) {
                var url = `/gateway/api/sycompany/pc/company/getCompanyByPage?orgCode=${this.$core.getUserInfo().orgCode || window.sessionStorage.getItem("orgCode")}`

                switch (v) {
                    case "review":
                        url += `&isQuery=2`
                        break
                    case "merchant":
                        url += `&isShop=1&isQuery=1`
                        break
                    // case "on_line":
                    //     url += `&isShop=1&isQuery=1`
                    //     break
                    case "stop_business":
                        url += `&businessStatus=2&isQuery=1`
                        break
                    default:
                        url += `&isQuery=1`
                }

                if (!(v !== "review" && o !== "review")) {
                    this.columns = this.getColumns(v === "review" ? "review" : "complete")
                }

                this.table_url = url
            },
            immediate: true,
        },
        iframeUrl(v) {
            v && this.$refs.modal.display()
        },
    },

    methods: {
        onSelectMenu(evt) {
            this.tabMenuActivity = evt
        },

        /**
         * 搜索查询
         */
        onSearch() {
            this.$refs.elem_table.search(this.searchData)
        },

        /**
         * 获取表格操作菜单
         */
        getTableOperate(type, h, params) {
            if (type === "review") {
                return []
            }

            return [
                //  h(
                //     "DropdownItem",
                //     {
                //         nativeOn: {
                //             click: () => {
                //                 this.onOpenMessageDetail(params.row)
                //             },
                //         },
                //         style: {
                //             textAlign: "center",
                //         },
                //     },
                //     "发消息"
                // ),
                h(
                    "DropdownItem",
                    {
                        nativeOn: {
                            click: () => {
                                this.$refs.data_form.open(params.row.companyId)
                            },
                        },
                        style: {
                            textAlign: "center",
                        },
                    },
                    "修改单位信息"
                ),
                type === "complete"
                    ? h(
                          "DropdownItem",
                          {
                              nativeOn: {
                                  click: () => {
                                      this.$refs.elem_datail.open(params.row.companyId)
                                  },
                              },
                              style: {
                                  textAlign: "center",
                              },
                          },
                          "详情页配置"
                      )
                    : null,
                params.row.shopId
                    ? h(
                          "DropdownItem",
                          {
                              nativeOn: {
                                  click: () => {
                                      this.$refs.staff.display(params.row.shopId)
                                  },
                              },
                              style: {
                                  textAlign: "center",
                              },
                          },
                          "店铺管理员"
                      )
                    : null,
                h(
                    "DropdownItem",
                    {
                        nativeOn: {
                            click: () => {
                                this.$refs.applyData.display(params.row.companyId)
                            },
                        },
                        style: {
                            textAlign: "center",
                        },
                    },
                    "商户申请资料"
                ),
                h(
                    "DropdownItem",
                    {
                        nativeOn: {
                            click: () => {
                                this.$refs.mechanism.open(params.row.companyId, params.row.orgCode)
                            },
                        },
                        style: {
                            textAlign: "center",
                        },
                    },
                    "创建/关联机构"
                ),
                h(
                    "DropdownItem",
                    {
                        nativeOn: {
                            click: () => {
                                this.$refs.add_conceal.display(params.row.companyId, params.row.name)
                            },
                        },
                        style: {
                            textAlign: "center",
                        },
                    },
                    "加入黑名单"
                ),
                h(
                    "DropdownItem",
                    {
                        nativeOn: {
                            click: () => {
                                this.$Modal.confirm({
                                    title: "提示",
                                    content: "是否删除该单位信息？",
                                    onOk: () => {
                                        this.$post("/gateway/api/sycompany/pc/company/deleteCompany", { companyId: params.row.companyId }).then(res => {
                                            if (res.code == 200) {
                                                this.$Message.success({
                                                    content: "删除成功",
                                                    background: true,
                                                })
                                                this.$refs.elem_table.reset()
                                            } else {
                                                this.$Message.error({
                                                    content: res.desc,
                                                    background: true,
                                                })
                                            }
                                        })
                                    },
                                })
                            },
                        },
                        style: {
                            textAlign: "center",
                        },
                    },
                    "删除"
                ),
            ]
        },
        onOpenMessageDetail(item) {
            const loading = this.$Message.loading({
                content: "连接中...",
                duration: 0,
            })

            this.$get("/gateway/chat/app/chat/onlyUser/flushUser", {
                onlyUserId: item.custGlobalId,
            })
                .then(res => {
                    if (res.code !== "200" || !res.data) {
                        return this.$Message.error(res.desc || res.msg || "建立连接失败，请稍后再试！")
                    }

                    this.iframeUrl = `${proxy["/custom"].target}/#/pages/im/person/chat?groupCode=${res.data}&groupName=${item.name}&businessType=1&businessCode=2&custGlobalId=${
                        item.custGlobalId
                    }&token=${window.sessionStorage.getItem("accessToken")}`
                })
                .finally(() => {
                    loading()
                })
        },
        /**
         * 重置
         */
        onReset() {
            // 清空搜索数据
            this.searchData = {}
            this.$refs.elem_table?.reset?.()
        },

        // 显示表单页面，ID 可选，传 ID 为编辑模式
        onDisplayForm(id) {
            this.$refs.data_form.open(id)
        },

        onChangeMechanism() {
            this.onReset()
        },

        onChangeLifeDisplay(id, val) {
            return new Promise(resolve => {
                this.$post(
                    `/gateway/api/sycompany/pc/company/updateBusinessStatus`,
                    {
                        companyId: id,
                        lifeDisplay: val ? "1" : "2",
                    },
                    { "Context-Type": "application/json" }
                ).then(res => {
                    resolve(res.code == 200)
                })
            })
        },

        /**
         * 修改开业状态
         */
        onChangeBusinessStatus(item) {
            return new Promise(resolve => {
                this.$post(
                    `/gateway/api/sycompany/pc/company/updateBusinessStatus`,
                    {
                        companyId: item.companyId,
                        endDate: item.businessStatus === "2" ? null : this.$core.formatDate(new Date(), "yyyy-MM-ddThh:mm:ss"),
                    },
                    { "Context-Type": "application/json" }
                ).then(res => {
                    resolve(res.code == 200)
                })
            })
        },

        // 服务范围模态框
        onChangeService(status) {
            if (!status) {
                this.openServiceStatus = status
                this.serviceCode = ""
            }
        },

        // 打开下级机构
        openSubordinate(status) {
            this.subordinateStatus = status
        },

        getColumns(type) {
            const res = [
                {
                    title: "编码",
                    key: "companyCode",
                    align: "center",
                },
                // {
                //     title: "线上店铺名称",
                //     key: "shopName",
                //     minWidth: 200,
                //     align: "center",
                // },
                {
                    title: "单位名称",
                    key: "name",
                    align: "center",
                    maxWidth: 300,
                    minWidth: 200,
                },
                {
                    title: "关联机构",
                    key: "orgCode",
                },
                ...(type === "complete"
                    ? [
                          {
                              title: "显示状态",
                              key: "appointmentStatus",
                              minWidth: 120,
                              align: "center",
                              render: (h, params) => {
                                  return h("i-switch", {
                                      props: {
                                          value: params.row.lifeDisplay === "1" ? true : false,
                                      },
                                      on: {
                                          "on-change": res => {
                                              // 延迟 500ms 执行，避免导致动画卡顿
                                              setTimeout(() => {
                                                  this.onChangeLifeDisplay(params.row.companyId, res).then(v => {
                                                      if (!v) {
                                                          // 失败时取反值
                                                          params.row.lifeDisplay = res ? "2" : "1"
                                                      }
                                                  })
                                              }, 500)
                                          },
                                      },
                                  })
                              },
                          },
                      ]
                    : []),
                {
                    title: "平台业务类型",
                    minWidth: 170,
                    align: "center",
                    render: (h, params) => {
                        var a = []

                        if (params.row.appointmentStatus == "1") {
                            a.push("可预约")
                        }

                        if (params.row.shopStatus == "1") {
                            a.push("可购买")
                        }

                        return h("p", {}, a.length <= 0 ? "-" : a.join(", "))
                    },
                },
                ...(type === "complete"
                    ? [
                          {
                              title: "营业状态",
                              minWidth: 120,
                              align: "center",
                              render: (h, params) => {
                                  return h("i-switch", {
                                      props: {
                                          value: params.row.businessStatus === "1",
                                      },
                                      on: {
                                          "on-change": () => {
                                              // 延迟 500ms 执行，避免导致动画卡顿
                                              setTimeout(() => {
                                                  this.onChangeBusinessStatus(params.row).then(res => {
                                                      if (res) {
                                                          params.row.businessStatus = params.row.businessStatus === "2" ? "1" : "2"
                                                      }
                                                  })
                                              }, 500)
                                          },
                                      },
                                  })
                              },
                          },
                      ]
                    : []),
                {
                    title: "申请账号",
                    minWidth: 200,
                    key: "applyAccount",
                    align: "center",
                },
                {
                    title: "入驻时间",
                    minWidth: 210,
                    align: "center",
                    render: (h, params) => {
                        if (!params.row.startDate) {
                            return h("div", {}, "-")
                        }

                        return h("div", {}, this.$core.formatDate(new Date(params.row.applyDate), "yyyy-MM-dd hh:mm:ss"))
                    },
                },
                ...(type === "complete"
                    ? [
                          {
                              title: "激活账号",
                              minWidth: 200,
                              key: "activateAccount",
                              align: "center",
                          },
                          {
                              title: "激活码",
                              minWidth: 150,
                              key: "activationCode",
                              align: "center",
                          },
                          {
                              title: "激活时间",
                              minWidth: 210,
                              align: "center",
                              render: (h, params) => {
                                  if (!params.row.activeDate) {
                                      return h("div", {}, "-")
                                  }
                                  return h("div", {}, this.$core.formatDate(new Date(params.row.activeDate), "yyyy-MM-dd hh:mm:ss"))
                              },
                          },
                          {
                              title: "店铺名称",
                              minWidth: 200,
                              key: "shopName",
                              align: "center",
                          },
                          {
                              title: "店铺ID",
                              minWidth: 120,
                              key: "shopId",
                              align: "center",
                          },
                      ]
                    : []),
                {
                    title: "ID",
                    key: "companyId",
                    minWidth: 350,
                    align: "center",
                },
                {
                    title: "操作",
                    fixed: "right",
                    minWidth: 150,
                    render: (h, params) => {
                        const o = []

                        if (type == "review") {
                            o.push(
                                h("Button", { props: { type: "primary", size: "small" }, style: { margin: "3px" }, on: { click: () => this.$refs.data_step_form.open(params.row.companyId) } }, "审核"),
                                h(
                                    "Button",
                                    {
                                        style: { margin: "3px" },
                                        props: { type: "error", size: "small" },
                                        on: {
                                            click: () => {
                                                this.$Modal.confirm({
                                                    title: "提示",
                                                    content: "是否删除该单位信息？",
                                                    onOk: () => {
                                                        this.$post("/gateway/api/sycompany/pc/company/deleteCompany", { companyId: params.row.companyId }).then(res => {
                                                            if (res.code == 200) {
                                                                this.$Message.success({
                                                                    content: "删除成功",
                                                                    background: true,
                                                                })
                                                                this.$refs.elem_table.reset()
                                                            } else {
                                                                this.$Message.error({
                                                                    content: res.desc,
                                                                    background: true,
                                                                })
                                                            }
                                                        })
                                                    },
                                                })
                                            },
                                        },
                                    },
                                    "删除"
                                )
                            )
                        }

                        const ot = this.getTableOperate(type === "review" ? "review" : "complete", h, params)

                        if (ot?.length > 0) {
                            o.push(
                                h(
                                    "Dropdown",
                                    {
                                        props: {
                                            transfer: true,
                                        },
                                    },
                                    [
                                        h(
                                            "Button",
                                            {
                                                props: {
                                                    type: "info",
                                                    size: "small",
                                                },
                                            },
                                            ["操作", h("Icon", { style: { marginLeft: "10px" }, props: { type: "ios-arrow-down" } })]
                                        ),
                                        h(
                                            "DropdownMenu",
                                            {
                                                slot: "list",
                                            },
                                            ot
                                        ),
                                    ]
                                )
                            )
                        }

                        o.push(
                            h(
                                "Button",
                                {
                                    props: { type: "primary", size: "small" },
                                    style: { margin: "3px" },
                                    on: {
                                        click: () =>
                                            this.$core.openLayerFrame({
                                                type: 2,
                                                title: "订单管理",
                                                content: `/page#/jointly/record?companyId=${params.row.companyId}`,
                                                area: ["100%", "100%"],
                                            }),
                                    },
                                },
                                "订单管理"
                            )
                        )

                        return o
                    },
                },
            ]

            return res
        },

        onChangeSelection(evt) {
            console.log(evt)
        },

        /**
         * 处理同步地名已选
         */
        processSyncPlaceNameSelected(item) {
            return item.row.isSyncScenic === "1"
        },

        /**
         * 同步到地名+
         */
        onSyncPlaceName() {
            const s = this.$refs.elem_table.getSelection()

            if (!s?.length) {
                return this.$Message.warning("请选择要同步的单位")
            }

            Request.post(
                "/gateway/api/syscenic/pc/point/syncCompanyToScenic",
                {
                    scenicId: 7,
                    companyIds: s.map(i => i.companyId),
                },
                {
                    json: true,
                }
            ).then(() => {
                this.$Message.success({
                    content: "同步成功",
                    background: true,
                })
                // 设置为已选，不可再选
                s.forEach(v => (v.isSyncScenic = "1"))
                // 清除已选项
                this.$refs.elem_table.clearSelecteds()
            })
        },
    },
}
</script>

<style lang="less" scoped>
.none-modal {
    /deep/.ivu-modal-close {
        display: none;
    }
}

.jointly-info-table-page {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .toolbar {
        position: sticky;
        top: 0;
        background: #fafafa;
        width: 100%;
        box-shadow: 0 0 1px 1px #ddd;
        z-index: 2;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-shrink: 0;

        .title-box {
            display: flex;
            align-items: center;
        }

        .tool-box {
            display: flex;
            align-items: center;
        }
    }

    .table-left {
        float: left;
        width: 180px;
        /deep/.ivu-menu {
            width: 180px !important;
        }
    }

    .content-box {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .search-box {
            padding: 5px 10px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            flex-shrink: 0;

            .search {
                > div {
                    margin: 8px 8px 8px 0 !important;
                    width: auto;
                    min-width: 200px;
                }
            }

            .btn {
                margin: 5px 10px 5px 0;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .table-box {
            margin: 0 20px 20px 20px;
            flex-grow: 1;
            overflow: hidden;

            .table-left {
                margin-right: 20px;
                height: 100%;

                /deep/.ivu-menu {
                    height: 100%;
                    z-index: 10;
                }
            }
        }
    }
}
</style>
