<template>
    <div class="jointly-mechanism-page">
        <CompModal ref="comp_modal" title="店铺管理员" isFull :onSuccess="onSubmit">
            <template #head-operate>
                <div class="modal-operate-btn" @click="onDisplayAdd">新增管理员</div>
            </template>
            <comp-table ref="comp_table" :columns="columns" :isToolbar="false" :table-api="tableApi"></comp-table>
        </CompModal>

        <CompForm
            ref="form"
            title="管理员"
            :isJson="false"
            submit-api="/gateway/apps/o2o/api/o2o/user/addSupplierUser"
            height="auto"
            :isScroll="false"
            :submit-before="onSubmitBefore"
            @on-submit="onReset"
        >
            <template #form-box="data">
                <ElemFormItem title="管理员" column>
                    <ElemSelect
                        :value="data.form.globalId"
                        style="width: 400px"
                        placeholder="请输入用户名或手机号码进行搜索"
                        :remotely="{ api: '/gateway/apps/basic/api/base/uc/getByMobileOrNickname', search: 'mobileOrNickname', id: 'globalId', value: 'realName' }"
                        @on-change="$set(data.form, 'globalId', $event.value)"
                    ></ElemSelect>
                </ElemFormItem>
            </template>
        </CompForm>

        <CompModal title="畅联通" ref="modal" width="400px" @on-close="iframeUrl = null">
            <iframe v-if="iframeUrl" :src="iframeUrl" width="100%" height="100%" frameborder="0" style="display: block"></iframe>
        </CompModal>
    </div>
</template>

<script>
import CompTable from "../../components/comp-table.vue"
import LiefengModal from "@/components/LiefengModal"
import MechanismFormStep from "./mechanism_form_step.vue"
import CompModal from "../../../residentdatabase/components/CompModal.vue"
import CompForm from "../../components/CompForm.vue"
import ElemSelect from "../../../shop/components/elem-select.vue"
import ElemFormItem from "../../components/elem-form-item.vue"
import proxy from "@/api/proxy"

export default {
    components: { CompTable, LiefengModal, CompModal, MechanismFormStep, CompForm, ElemSelect, ElemFormItem },

    data() {
        return {
            // 表格 API 地址
            tableApi: null,

            columns: [
                {
                    title: "管理员姓名",
                    key: "name",
                    minWidth: 200,
                    align: "center",
                },
                {
                    title: "管理员角色",
                    minWidth: 140,
                    align: "center",
                    render: (h, params) => {
                        return h("span", {}, params.row.role === "1" ? "超级管理员" : "管理员")
                    },
                },
                {
                    title: "手机号码",
                    key: "mobile",
                    minWidth: 250,
                    align: "center",
                },
                {
                    title: "加入时间",
                    minWidth: 280,
                    align: "center",
                    render: (h, params) => {
                        return h("span", {}, this.$core.formatDate(new Date(params.row.gmtCreate), "yyyy-MM-dd hh:mm:ss"))
                    },
                },
                {
                    title: "操作",
                    fixed: "right",
                    minWidth: 250,
                    align: "center",
                    render: (h, params) => {
                        const arr = []

                        console.log(params)

                        if (params.row.role !== "1") {
                            arr.push(
                                h(
                                    "Button",
                                    {
                                        props: {
                                            type: "info",
                                            size: "small",
                                        },
                                        style: "margin:3px",
                                        nativeOn: {
                                            click: () => {
                                                this.$Modal.confirm({
                                                    title: "提示",
                                                    content: "当前账号设置为超级管理员的同时会将现有超级管理员设为普通管理员，是否继续？",
                                                    onOk: () => {
                                                        this.$post(
                                                            "/gateway/api/sycompany/pc/user/transferCompanyUser",
                                                            {
                                                                supplierId: params.row.companyId,
                                                                transferUserId: params.row.custGlobalId,
                                                            },
                                                            {
                                                                "content-type": "application/json",
                                                            }
                                                        ).then(res => {
                                                            if (res.code == 200) {
                                                                this.$Message.success({
                                                                    content: "设置成功",
                                                                    background: true,
                                                                })
                                                                // 刷新表格数据
                                                                this.$refs.comp_table.refresh()
                                                            } else {
                                                                this.$Message.error({
                                                                    content: res.desc || "请求失败",
                                                                    background: true,
                                                                })
                                                            }
                                                        })
                                                    },
                                                })
                                            },
                                        },
                                    },
                                    "设为超级管理员"
                                )
                            )
                        }

                        arr.push(
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "error",
                                        size: "small",
                                    },
                                    style: "margin:3px",
                                    nativeOn: {
                                        click: () => {
                                            this.$Modal.confirm({
                                                title: "删除确定",
                                                content: "您正在删除数据，是否继续？",
                                                onOk: () => {
                                                    this.$post("/gateway/apps/o2o/api/o2o/user/deleteSupplierUser", {
                                                        supplierId: params.row.companyId,
                                                        globalId: params.row.custGlobalId,
                                                    }).then(res => {
                                                        if (res.code == 200) {
                                                            this.$Message.success({
                                                                content: "删除成功",
                                                                background: true,
                                                            })
                                                            // 刷新表格数据
                                                            this.$refs.comp_table.refresh()
                                                        } else {
                                                            this.$Message.error({
                                                                content: res.desc || "删除失败",
                                                                background: true,
                                                            })
                                                        }
                                                    })
                                                },
                                            })
                                        },
                                    },
                                },
                                "删除"
                            ),
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "primary",
                                        size: "small",
                                    },
                                    style: "margin:3px",
                                    nativeOn: {
                                        click: () => {
                                            this.onOpenMessageDetail(params.row)
                                        },
                                    },
                                },
                                "发消息"
                            )
                        )

                        return h("div", arr)
                    },
                },
            ],

            iframeUrl:''
        }
    },

    methods: {
         onOpenMessageDetail(item) {
            const loading = this.$Message.loading({
                content: "连接中...",
                duration: 0,
            })

            this.$get("/gateway/chat/app/chat/onlyUser/flushUser", {
                onlyUserId: item.custGlobalId,
            })
                .then(res => {
                    if (res.code !== "200" || !res.data) {
                        return this.$Message.error(res.desc || res.msg || "建立连接失败，请稍后再试！")
                    }

                    this.iframeUrl = `${proxy["/custom"].target}/#/pages/im/person/chat?groupCode=${res.data}&groupName=${item.userName}&businessType=1&businessCode=2&custGlobalId=${
                        item.custGlobalId
                    }&token=${window.sessionStorage.getItem("accessToken")}`
                })
                .finally(() => {
                    loading()
                })
        },
        display(id) {
            this.supplierId = id
            this.tableApi = `/gateway/apps/o2o/api/o2o/user/listSupplierUser?supplierId=${id}`
            this.$refs.comp_modal.display()
        },

        onReset() {
            this.$refs.comp_table.refresh()
        },

        /**
         * 新增机构
         */
        onDisplayAdd() {
            this.$refs.form.display()
        },

        onSubmitBefore(data) {
            data.supplierId = this.supplierId
            return data
        },
    },
}
</script>

<style lang="less">
.modal-operate-btn {
    cursor: pointer;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #2faaf7;
    color: #fff;
    border-radius: 4px;
    height: 32px;
}
</style>
